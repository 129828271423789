import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import * as AllRules from '@vee-validate/rules';
import { errorMessages } from './errorMessages';

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});

configure({
  // Generates an English message locale generator
  generateMessage: localize(errorMessages)
});
