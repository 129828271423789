<template>
  <SingleNumberChart chart-id="total-offers"
    :config="chartConfig"
    :result="chartResult"
    :loading="chartLoading"
    :error="chartError">
    <template #header>
      Total Offers
      <div class="chart-section-header-actions">
        <slot name="header-actions" />
      </div>
    </template>
  </SingleNumberChart>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import SingleNumberChart from "@/src/components/shared/charts/SingleNumberChart.vue";

export default {
  components: {
    SingleNumberChart,
  },
  mixins: [chartMixin],
  data() {
    return {
      chartConfig: {
        tooltip: 'The total number of offers to your transplant center',
      },
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.getSingleChartResult(EP.external_analytics.total_offers, filterParameters);
    },
  },
}
</script>

<style scoped>
  .chart-section-header-actions {
    float: right;
  }
</style>
