<template>
  <HorizontalBarChart chart-id="chart-transplanted-organs"
    :config="chartConfig"
    :results="chartResults"
    :loading="chartLoading"
    :error="chartError">
    <template #header>
      Transplanted Organs
    </template>
  </HorizontalBarChart>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import HorizontalBarChart from "@/src/components/shared/charts/HorizontalBarChart.vue";

export default {
  components: {
    HorizontalBarChart,
  },
  mixins: [chartMixin],
  data() {
    return {
      chartConfig: {
        xTitle: 'Total Transplanted Organs',
        barColours: ['#A989C5'],
        resultLabelField: 'organ_name',
        datasets: [
          {
            dataField: 'number_of_transplants',
            label: 'Number of Transplants',
          }
        ]
      },
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.getChartResults(EP.external_analytics.chart_transplanted_organs, filterParameters);
    },
  },
}
</script>
