<template>
  <SinglePercentageChart chart-id="percent-auto-rule-outs-for-fully-declined-offers"
    :result="chartResult"
    :loading="chartLoading"
    :error="chartError">
    <template #header>
      Auto Rule Outs for Fully Declined Offers
    </template>
  </SinglePercentageChart>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import SinglePercentageChart from "@/src/components/shared/charts/SinglePercentageChart.vue";

export default {
  components: {
    SinglePercentageChart,
  },
  mixins: [chartMixin],
  methods: {
    loadChartData(filterParameters) {
      this.getSingleChartResult(EP.external_analytics.percent_auto_rule_outs_for_fully_declined_offers, filterParameters);
    },
  },
}
</script>
