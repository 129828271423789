import beApiClient from "~/src/be-api-client";
import { APIRoute } from "~/src/endpoints";

// This mixin is responsible for common functionality used by analytics charts
export const chartMixin = {
  data() {
    return {
      chartLoading: true,
      chartError: '',
      chartResult: {},
      chartResults: [],
    };
  },
  methods: {
    getSingleChartResult(endpoint, params) {
      const _vm = this;
      const url = APIRoute(endpoint);
      _vm.startLoading();
      beApiClient.get(url, {
        headers: { 'Accept': 'application/json' },
        params: params,
      }).then((response) => {
        _vm.handleSingleResultResponse(response);
      }).catch((error) => {
        _vm.handleError(error);
      }).finally(() => {
        _vm.stopLoading();
      });
    },
    getChartResults(endpoint, params) {
      const _vm = this;
      const url = APIRoute(endpoint);
      _vm.startLoading();
      beApiClient.get(url, {
        headers: { 'Accept': 'application/json' },
        params: params,
      }).then((response) => {
        _vm.handleResultsResponse(response);
      }).catch((error) => {
        _vm.handleError(error);
      }).finally(() => {
        _vm.stopLoading();
      });
    },
    startLoading() {
      this.chartLoading = true;
      this.chartResult = {};
      this.chartResults = [];
      this.chartError = '';
    },
    stopLoading() {
      this.chartLoading = false;
    },
    handleSingleResultResponse(response) {
      const analyticsResult = response.data?.analytics_result;
      if (analyticsResult == null) this.handleError({ message: 'Missing analytics result' });

      this.chartResult = analyticsResult;
    },
    handleResultsResponse(response) {
      const analyticsResults = response.data?.analytics_results;
      if (analyticsResults == null) this.handleError({ message: 'Missing analytics results' });

      this.chartResults = analyticsResults;
    },
    handleError(error) {
      console.warn(error);
      const errorMessage = error.response?.data?.errors || error.message;
      this.chartError = errorMessage;
    },
  },
}
