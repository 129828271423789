import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP, APIBaseRoute} from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useTeamStore = defineStore('team', {
  state: ()=> {
    return {
      // lookup data
      teams: [],
      roles: [],
      usersOnTeam: [],
      membershipCategories: [],
      transplantCenters: [],
      organGroups: [],
      allTransplantCenters: []
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    loadPaginatedEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, {
          headers: {'Accept': 'application/json'},
          params: params })
          .then(response => {
            this[key] = response.data;
            resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    // Load all teams
    loadTeams(params) {
      return this.loadPaginatedEntity(APIRoute(EP.admin.teams.index, params), 'teams', params)
    },
    // Load Membership Categories
    loadMembershipCategories() {
      return this.loadEntity(APIRoute(EP.admin.membership_categories.index), 'membershipCategories')
    },
    // load all transplant centres in the db, irrespective of user permissions
    loadAllTransplantCentres() {
      return this.loadPaginatedEntity(APIRoute(EP.admin.transplant_centers.index), 'allTransplantCenters',{ all: true })
    },
    // load transplant centres
    loadTransplantCentres() {
      return this.loadEntity(APIRoute(EP.admin.transplant_centers.index), 'transplantCenters')
    },
    // load organ groups
    loadOrganGroups() {
      return this.loadEntity(APIRoute(EP.admin.organ_groups.index), 'organGroups')
    },
    makeRequest(method, url, payload = null) {
      return new Promise((resolve, reject) => {
        method(url, payload).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      })
    },
    // Load Team
    loadTeam(id) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.teams.show, { id: id }));
    },
    createTeam(payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.admin.teams.create), payload);
    },
    updateTeam(payload) {
      return makeRequest(beApiClient.patch, APIRoute(EP.admin.teams.update, { id: payload.team.id }), payload);
    },
    deleteTeam(id) {
      return makeRequest(beApiClient.delete, APIRoute(EP.admin.teams.delete, { id: id }));
    },
    loadUsersOnTeam(id,payload) {
      return this.loadPaginatedEntity(APIRoute(EP.admin.teams.users_on_team,{ team_id: id }), 'usersOnTeam',payload)
    },
    loadUsersMissingFromTeam(id) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.teams.users_missing_from_team, { team_id: id }));
    },
    addUsersToTeam(id, payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.admin.teams.add_users_to_team, { team_id: id }), payload);
    },
    // Delete Team Membership
    deleteMembership(id, payload) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.teams.delete_membership, { team_id: id}), { details: payload });
    },
  }
});