<template>
  <SinglePercentageChart chart-id="percent-accepted-after-decline"
    :config="chartConfig"
    :result="chartResult"
    :loading="chartLoading"
    :error="chartError">
    <template #header>
      Accepted After Decline
    </template>
  </SinglePercentageChart>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import SinglePercentageChart from "@/src/components/shared/charts/SinglePercentageChart.vue";

export default {
  components: {
    SinglePercentageChart,
  },
  mixins: [chartMixin],
  data() {
    return {
      chartConfig: {
        tooltip: 'The percentage of organs offered to your TxC that you declined, that were accepted by another TxC (denominator: offers to your TxC)',
      },
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.getSingleChartResult(EP.external_analytics.percent_accepted_after_decline, filterParameters);
    },
  },
}
</script>
