<template>
  <chart-section
    :sizeClasses="sizeClasses"
    :loading="loading"
    :error="error"
  >
    <template v-slot:header>
      <slot name="header">
        Default Header in Stacked Horizontal Bar Chart
      </slot>
    </template>
    <template v-slot:body>
      <Bar
        v-if="!loading && !error"
        :id="chartId"
        :options="chartOptions"
        :data="chartData"
      />
      <div v-else />
    </template>
  </chart-section>
</template>

<script lang="js">
import ChartSection from "@/src/components/shared/ChartSection.vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
} from 'chart.js'
import { Bar } from 'vue-chartjs'

const DEFAULTS = {
  INDEX_AXIS: 'y',
  DATASET_LABEL_NAME: 'Default Label for Dataset in Stacked Horizontal Bar Chart',
  SCALE_X_TITLE_TEXT: 'Default Title for X Scale in Stacked Horizontal Bar Chart',
  RESULT_LABEL_FIELD: 'organ_name',
  BAR_COLOURS: ['#98D9D9'],
  STACKED: false,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
)

export default {
  components: {
    ChartSection,
    Bar
  },
  props: {
    chartId: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: false,
      default: () => {},
    },
    results: {
      required: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    sizeClasses: {
      type: String,
      required: false,
      default: 'col-md-6 col-sm-12',
    }
  },
  computed: {
    chartOptions() {
      const result = {
        indexAxis: DEFAULTS.INDEX_AXIS,
        scales: {
          x: {
            title: {
              display: true,
              text: DEFAULTS.SCALE_X_TITLE_TEXT,
            },
            stacked: true,
          },
          y: {
            grid: {
              display: false,
            },
            stacked: true,
          },
        },
        plugins: {
          legend: {
            display: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
      const configObject = this.config || {};
      if (configObject.xTitle) result.scales.x.title.text = configObject.xTitle;
      return result;
    },
    chartData() {
      const configObject = this.config || {};
      const resultsArray = this.results || [];
      const datasetConfigs = configObject.datasets || [];
      const resultLabelField = configObject.resultLabelField || DEFAULTS.RESULT_LABEL_FIELD;
      const barColours = configObject.barColours || DEFAULTS.BAR_COLOURS;
      const datasetLabelName = DEFAULTS.DATASET_LABEL_NAME;
      return {
        labels: this.buildLabels(datasetConfigs),
        datasets: this.buildDatasets(datasetConfigs, resultsArray, resultLabelField, barColours),
      };
    },
  },
  methods: {
    buildLabels(datasetConfigs) {
      return datasetConfigs.map((datasetConfig) => {
        return datasetConfig.label;
      });
    },
    buildDatasets(datasetConfigs, resultsArray, resultLabelField, barColours) {
      return resultsArray.map((result, index) => {
        const dataset = {
          label: result[resultLabelField],
          backgroundColor: this.buildBarColour(barColours, index),
          data: [],
        };
        dataset.data = datasetConfigs.map((datasetConfig) => {
          return result[datasetConfig.dataField]
        });
        return dataset;
      });
    },
    buildBarColour(barColours, index) {
      return barColours[index % barColours.length];
    },
  },
}
</script>
