export const teamSubSectionNavMixin = {
  computed: {
    subSectionNav() {
      if(!this.$route.params.id) return null;
      return [
        { to: { name: 'admin_teams_edit', params: { id: this.$route.params.id } }, label: 'Edit' },
        { to: { name: 'admin_teams_edit_manage', params: { id: this.$route.params.id } }, label: 'Manage Members' },
        { to: { name: 'admin_teams_edit_advanced', params: { id: this.$route.params.id } }, label: 'Advanced' },
      ]
    }
  }
}
