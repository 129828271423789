<template>
  <StackedHorizontalBarChart chart-id="chart-final-dispositions"
    size-classes="col-12"
    :config="chartConfig"
    :results="chartResults"
    :loading="chartLoading"
    :error="chartError">
    <template #header>
      Final Dispositions
    </template>
  </StackedHorizontalBarChart>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import StackedHorizontalBarChart from "@/src/components/shared/charts/StackedHorizontalBarChart.vue";

export default {
  components: {
    StackedHorizontalBarChart,
  },
  mixins: [chartMixin],
  data() {
    return {
      chartConfig: {
        stacked: true,
        xTitle: 'Total Matches',
        barColours: [
          '#509EE3',
          '#88BF4D',
          '#98D9D9',
          '#F7C41F',
          '#F2A86F',
          '#EF8C8C',
          '#7172AD',
        ],
        resultLabelField: 'organ_name',
        datasets: [
          {
            label: 'Backup offer never primary',
            dataField: 'backup_offer_never_primary',
          },
          {
            label: 'Accepted after decline',
            dataField: 'accepted_after_decline',
          },
          {
            label: 'Not accepted',
            dataField: 'not_accepted',
          },
          {
            label: 'Accepted cases',
            dataField: 'accepted_cases',
          },
          {
            label: 'No Transplant - Dry Run',
            dataField: 'no_transplant_dry_run',
          },
          {
            label: 'Other',
            dataField: 'other',
          },
          {
            label: 'None',
            dataField: 'none',
          },
        ]
      },
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.getChartResults(EP.external_analytics.chart_final_dispositions, filterParameters);
    },
  },
}
</script>
