<template>
  <header ref="site-header"
    class="header">
    <div class="header-inner">
      <div class="nav-top">
        <div class="app-logo">
          <router-link :to="{ name: 'app-root' }" class="nav-link">
            <img src="../../../../assets/images/logo-bt.png"
              alt="Buckeye Transplant">
          </router-link>
          <button type="submit" class="btn btn-toggle shadow-none d-md-none" :class="`${show ? 'active' : ''}`" @click="toggleSearch()">
            <font-awesome-icon :icon="['fas', 'bars']" />
            <span class="sr-only">toggle-menu</span>
          </button>
        </div>
      </div>

      <div v-if="currentUser" class="navbar">
        <div class="navbar-wrap">
          <ul class="navbar-list d-md-flex" :class="`${show ? 'd-flex' : 'd-none '}`" style="flex-direction: row; align-items:center;">
            <li v-if="currentUser.canRead(PermissionNames.AREA_EXTERNAL)" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'external-dashboards-root'}" title="TxC Home">
                TxC Home
              </router-link>
            </li>
            <li v-if="currentUser.canRead(PermissionNames.AREA_INTERNAL)" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'internal-dashboards-root'}" title="Buckeye Home">
                Buckeye Home
              </router-link>
            </li>
            <li v-if="currentUser.canRead(PermissionNames.AREA_ADMIN)" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'admin_root'}" title="Admin Area">
                Admin Area
              </router-link>
            </li>
          </ul>
        </div>
        <div class="dropdown user-dropdown">
          <a id="user-drop-down" href="#" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            class="nav-link dropdown-toggle media" style="align-items: center;"
            :title="currentUser.profileInfo.full_name">
            <font-awesome-icon :icon="['fas', 'user-circle']" class="headerUserIcon" />
            <span class="d-none d-md-block userName" style="display: inline-block;">{{ currentUser.profileInfo.full_name }}</span>
          </a>
          <div aria-labelledby="user-drop-down" class="dropdown-menu dropdown-menu-right" title="Log out">
            <a href="/users/sign_out" class="dropdown-item">
              <font-awesome-icon :icon="['fas', 'sign-out']" class="headerMenuIcon pr-2" />
              <span class="d-sm-inline">Log out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="js">
import {useAppCoreStore} from "@/src/stores/app-core";
import {PermissionNames} from "@/src/enums";

export default {
  setup() {
    const appStore = useAppCoreStore();    
    return {
      appStore,
      PermissionNames,
    }
  },
  data() {
    return {
       show:false
    }
  },
  computed: {
    currentUser() {
      return this.appStore.currentUser;
    },
  },
  methods: {
    toggleSearch() {
      this.show = !this.show;
    },
  }

}
</script>
