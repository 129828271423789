import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP} from "@/src/endpoints";
import { handleErrors } from "@/src/utils";

export class User {
  profileInfo; 
  userPermissions;

  constructor(apiResponse) {
    this.profileInfo = apiResponse; // Need to pull out information later
    this._processUserPermissions();
  }

  canRead(permissionName) {
    // Need to check if userPermissions exist because if role is not set up properly
    // it will result in a specific userPermissions being empty
    return this.userPermissions[permissionName] && this.userPermissions[permissionName].read;
  }

  getPreferences() {
    return this.profileInfo.ui_preference || {};
  }

  getColumnPreferences(columnKey, optionDefaults) {
    const preferences = this.getPreferences(); // get preferences
    if (preferences[columnKey]) {
      return preferences[columnKey].length > 0 ? preferences[columnKey] : optionDefaults;
    }
    return optionDefaults;
  }

  hasPermission(key) {
    if(!this.userPermissions) return false;
    const pr = this.userPermissions[key] && this.userPermissions[key].read == true ? true : false;
    return pr;
  }

  _processUserPermissions() {
    const userPermissions = {};
    this.profileInfo.team_memberships.forEach((tm) => {
      if(!tm.role) return; // This can happen if a team membership is set up incorrectly
      tm.role.role_permissions.forEach((rp) => {
        userPermissions[rp.permission.entity] = userPermissions[rp.permission.entity] || {create: false, read: false, write: false}
        userPermissions[rp.permission.entity].read = userPermissions[rp.permission.entity].read || rp.read
        userPermissions[rp.permission.entity].create = userPermissions[rp.permission.entity].create || rp.create
        userPermissions[rp.permission.entity].write = userPermissions[rp.permission.entity].write || rp.write
      })
    })
    this.userPermissions = userPermissions;
  }
}

export const useAppCoreStore = defineStore('app-core', {
  state: ()=> {
    return {
      currentUser: null,
      applicationSettings: null,
      appBreadcrumbs: [],
    }
  },
  getters: {
    getPluginVersion() {
      return this.applicationSettings && this.applicationSettings.plugin_version ? this.applicationSettings.plugin_version : null;
    },
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    async loadApplicationData() {
      return this.loadEntity(APIRoute(EP.application.settings), 'applicationSettings');
    },
    /**
     * Store current user
     * 
     */
    loadCurrentUser() {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.current_user.show)).then(currentUserResponse => {
          this.currentUser = new User(currentUserResponse.data.user);
          resolve();
        }).catch(error => {
          reject(error);
        })
      });
    },
    // Update Preferences
    updatePreferences(payload) {
      return new Promise((resolve, reject) => {
        beApiClient.put(APIRoute(EP.current_user.update_preferences), { preference: payload}).then(currentUserResponse => {
          this.currentUser = new User(currentUserResponse.data.user);
          resolve();
        }).catch(error => {
          reject(error);
        })
      });
    },
    /**
     * Set breadcrumb
     * 
     * @param {Object[]} crumbs
     * @param {Object} [crumbs.to]
     * @param {String} crumbs.text
     */
    setAppBreadcrumbs(crumbs) {
      this.appBreadcrumbs = [...crumbs];
    },
  }
})
